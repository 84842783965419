<template>
    <div>
        <!-- Main Content -->
        <div class="container mx-auto p-6">
            <h2 class="text-2xl font-bold mb-6 text-center">Investment Backtesting</h2>
            <div class="flex flex-wrap lg:flex-nowrap">
                <form @submit.prevent="calculateGrowthDebounced" class="w-full lg:w-1/2 lg:flex-grow-0 lg:flex-shrink-0 lg:basis-1/2 bg-white p-6 rounded-lg shadow-md mb-6 lg:mb-0">
                    <div class="mb-4">
                        <label for="initialInvestment" class="block text-gray-700">Initial Investment (€):</label>
                        <input type="number" v-model.number="initialInvestment" class="w-full px-3 py-2 border rounded" required />
                    </div>
                    <div class="mb-4">
                        <label for="initialSavings" class="block text-gray-700">Initial Savings (€):</label>
                        <input type="number" v-model.number="initialSavings" class="w-full px-3 py-2 border rounded" required />
                    </div>
                    <div class="mb-4">
                        <label for="monthlyPortfolioContribution" class="block text-gray-700">Monthly Portfolio Contribution (€):</label>
                        <input type="number" v-model.number="monthlyPortfolioContribution" class="w-full px-3 py-2 border rounded" />
                    </div>
                    <div class="mb-4">
                        <label for="monthlySavingsContribution" class="block text-gray-700">Monthly Savings Contribution (€):</label>
                        <input type="number" v-model.number="monthlySavingsContribution" class="w-full px-3 py-2 border rounded" />
                    </div>
                    <div class="mb-4">
                        <label for="years" class="block text-gray-700">Number of Years:</label>
                        <input type="number" v-model.number="years" class="w-full px-3 py-2 border rounded" required />
                    </div>
                    <div class="mb-4">
                        <label for="annualReturn" class="block text-gray-700">Expected Annual Return (%):</label>
                        <input type="number" v-model.number="annualReturn" step="0.01" class="w-full px-3 py-2 border rounded" required />
                    </div>
                    <div class="mb-4">
                        <label for="bankInterest" class="block text-gray-700">Bank Interest Rate (%):</label>
                        <input type="number" v-model.number="bankInterest" step="0.01" class="w-full px-3 py-2 border rounded" required />
                    </div>
                    <button type="submit" class="w-full bg-blue-500 text-white py-2 px-4 rounded">Calculate Growth</button>
                </form>
                <div class="w-full lg:w-1/2 lg:pl-6 lg:flex-grow">
                    <div class="my-4" v-if="growthData.length > 0">
                        <p class="text-gray-700">Portfolio after {{years}} years: <strong>{{ formatCurrency(finalInvestmentValue) }}</strong></p>
                        <p class="text-gray-700">Bank cash after {{years}} years: <strong>{{ formatCurrency(finalCashValue) }}</strong></p>
                        <p class="text-gray-700">Total Portfolio Growth: <strong>{{ portfolioGrowth }}%</strong></p>
                        <p class="text-gray-700">Total Cash Growth: <strong>{{ cashGrowth }}%</strong></p>
                    </div>
                    <div v-if="chartData.labels.length > 0" class="bg-white p-6 rounded-lg shadow-md mb-6">
                        <h3 class="text-xl font-bold mb-4">Growth Chart</h3>
                        <growth-chart :chart-data="chartData" />
                    </div>
                    <div v-if="growthData.length > 0" class="bg-white p-6 rounded-lg shadow-md">
                        <h3 class="text-xl font-bold mb-4">Monthly Growth</h3>
                        <table class="w-full table-auto">
                            <thead>
                            <tr>
                                <th class="px-4 py-2 border">Month</th>
                                <th class="px-4 py-2 border">Investment Portfolio Value (€)</th>
                                <th class="px-4 py-2 border">Bank Cash Value (€)</th>
                            </tr>
                            </thead>
                            <tbody>
                            <template v-for="(data, index) in growthData" :key="index">
                                <tr v-if="data.isYearEnd" class="summary-row font-bold bg-gray-100">
                                    <td class="px-4 py-2 border">Year {{ data.year }} Summary</td>
                                    <td class="px-4 py-2 border">{{ formatCurrency(data.totalInvestmentValue) }}</td>
                                    <td class="px-4 py-2 border">{{ formatCurrency(data.totalCashValue) }}</td>
                                </tr>
                                <tr v-else>
                                    <td class="px-4 py-2 border">{{ data.month }}</td>
                                    <td class="px-4 py-2 border">{{ formatCurrency(data.investmentValue) }}</td>
                                    <td class="px-4 py-2 border">{{ formatCurrency(data.cashValue) }}</td>
                                </tr>
                            </template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import GrowthChart from './GrowthChart.vue';
import _ from 'lodash';

export default {
    name: 'InvestmentBacktesting',
    components: {
        GrowthChart,
    },
    data() {
        return {
            initialInvestment: 0,
            initialSavings: 0,
            finalInvestmentValue: 0,
            finalCashValue: 0,
            monthlyPortfolioContribution: 0,
            monthlySavingsContribution: 0,
            years: 0,
            annualReturn: 0,
            bankInterest: 0,
            growthData: [],
            chartData: {
                labels: [],
                datasets: [
                    {
                        label: 'Investment Portfolio Value',
                        backgroundColor: '#42A5F5',
                        data: [],
                    },
                    {
                        label: 'Bank Cash Value',
                        backgroundColor: '#66BB6A',
                        data: [],
                    },
                ],
            },
            portfolioGrowth: 0,
            cashGrowth: 0,
        };
    },
    mounted() {
        this.loadInputs();
    },
    created() {
        document.title = 'Portfolio and savings calculator | Quotez';
    },
    watch: {
        initialInvestment: 'saveInputsDebounced',
        initialSavings: 'saveInputsDebounced',
        monthlyPortfolioContribution: 'saveInputsDebounced',
        monthlySavingsContribution: 'saveInputsDebounced',
        years: 'saveInputsDebounced',
        annualReturn: 'saveInputsDebounced',
        bankInterest: 'saveInputsDebounced',
    },
    methods: {
        saveInputs() {
            const inputs = {
                initialInvestment: this.initialInvestment,
                initialSavings: this.initialSavings,
                monthlyPortfolioContribution: this.monthlyPortfolioContribution,
                monthlySavingsContribution: this.monthlySavingsContribution,
                years: this.years,
                annualReturn: this.annualReturn,
                bankInterest: this.bankInterest,
            };
            localStorage.setItem('investmentBacktestingInputs', JSON.stringify(inputs));
        },
        saveInputsDebounced: _.debounce(function() {
            this.saveInputs();
        }, 300),
        loadInputs() {
            const savedInputs = localStorage.getItem('investmentBacktestingInputs');
            if (savedInputs) {
                const inputs = JSON.parse(savedInputs);
                this.initialInvestment = inputs.initialInvestment;
                this.initialSavings = inputs.initialSavings;
                this.monthlyPortfolioContribution = inputs.monthlyPortfolioContribution;
                this.monthlySavingsContribution = inputs.monthlySavingsContribution;
                this.years = inputs.years;
                this.annualReturn = inputs.annualReturn;
                this.bankInterest = inputs.bankInterest;
            }
        },
        calculateGrowth() {
            const months = this.years * 12;
            const monthlyReturnRate = (1 + this.annualReturn / 100) ** (1 / 12) - 1;
            const monthlyBankInterestRate = (1 + this.bankInterest / 100) ** (1 / 12) - 1;

            let investmentValue = this.initialInvestment;
            let cashValue = this.initialSavings;
            this.growthData = [];
            this.chartData = {
                labels: [],
                datasets: [
                    {
                        label: 'Investment Portfolio Value',
                        backgroundColor: '#42A5F5',
                        data: [],
                    },
                    {
                        label: 'Bank Cash Value',
                        backgroundColor: '#66BB6A',
                        data: [],
                    },
                ],
            };

            const startDate = new Date();

            for (let i = 0; i < months; i++) {
                investmentValue *= 1 + monthlyReturnRate;
                investmentValue += this.monthlyPortfolioContribution;

                cashValue *= 1 + monthlyBankInterestRate;
                cashValue += this.monthlySavingsContribution;

                const currentDate = new Date(startDate);
                currentDate.setMonth(currentDate.getMonth() + i);
                const monthYear = currentDate.toLocaleString('default', { month: 'long', year: 'numeric' });

                this.growthData.push({
                    month: monthYear,
                    investmentValue,
                    cashValue,
                });

                this.chartData.labels.push(monthYear);
                this.chartData.datasets[0].data.push(investmentValue);
                this.chartData.datasets[1].data.push(cashValue);

                // Check if it's the end of the year or the last month
                if ((currentDate.getMonth() === 11) || (i === months - 1)) {
                    const year = currentDate.getFullYear();
                    this.growthData.push({
                        month: `Year ${year} Summary`,
                        investmentValue,
                        cashValue,
                        isYearEnd: true,
                        year,
                        totalInvestmentValue: investmentValue,
                        totalCashValue: cashValue,
                    });
                }
            }

            this.calculateGrowthPercentages();
        },
        calculateGrowthDebounced: _.debounce(function() {
            this.calculateGrowth();
        }, 300),
        calculateGrowthPercentages() {
            this.finalInvestmentValue = this.growthData[this.growthData.length - 1].investmentValue;
            this.finalCashValue = this.growthData[this.growthData.length - 1].cashValue;

            this.portfolioGrowth = (((this.finalInvestmentValue - this.initialInvestment) / this.initialInvestment) * 100).toFixed(2);
            this.cashGrowth = (((this.finalCashValue - this.initialSavings) / this.initialSavings) * 100).toFixed(2);
        },
        formatCurrency(value) {
            return new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(value);
        },
    },
};
</script>

<style scoped>
.investment-backtesting {
    max-width: 1200px;
    margin: 0 auto;
}

@media (min-width: 1024px) {
    .investment-backtesting {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}
</style>
