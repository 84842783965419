<template>
    <div>
        <!-- Menu -->
        <nav class="bg-gray-800 p-4">
            <div class="container mx-auto flex justify-between items-center">
                <div class="text-white font-bold">Quotez</div>
                <div>
<!--                    <a href="#" class="text-gray-300 hover:text-white px-3">Home</a>-->
<!--                    <a href="#" class="text-gray-300 hover:text-white px-3">About</a>-->
<!--                    <a href="#" class="text-gray-300 hover:text-white px-3">Contact</a>-->
                </div>
            </div>
        </nav>

        <InvestmentBacktesting />

        <!-- Footer -->
        <footer class="bg-gray-800 text-white py-8 mt-12">
            <div class="container mx-auto grid grid-cols-1 sm:grid-cols-3 gap-4 text-center sm:text-left">
                <div>
                    <h5 class="text-lg font-bold mb-2">About Us</h5>
                    <p>Maximize your investment strategies with our advanced backtesting services. At Quotez, we offer comprehensive investment backtesting solutions designed to help you make informed decisions and optimize your portfolio. Our cutting-edge tools and methodologies allow you to simulate your trading strategies against historical data, providing invaluable insights into their potential performance. By analyzing past market conditions and trends, you can identify strengths, weaknesses, and areas for improvement in your strategies. Trust Quotez for accurate, reliable, and insightful backtesting that empowers you to achieve your financial goals with confidence. Explore our services today and take the first step towards smarter investing.</p>
                </div>
<!--                <div>-->
<!--                    <h5 class="text-lg font-bold mb-2">Quick Links</h5>-->
<!--                    <ul>-->
<!--                        <li><a href="#" class="hover:underline">Home</a></li>-->
<!--                        <li><a href="#" class="hover:underline">About</a></li>-->
<!--                        <li><a href="#" class="hover:underline">Services</a></li>-->
<!--                        <li><a href="#" class="hover:underline">Contact</a></li>-->
<!--                    </ul>-->
<!--                </div>-->
<!--                <div>-->
<!--                    <h5 class="text-lg font-bold mb-2">Contact Us</h5>-->
<!--                    <p>123 Investment St.<br />Finance City, FC 12345<br />Phone: (123) 456-7890<br />Email: info@investment.com</p>-->
<!--                </div>-->
            </div>
        </footer>
    </div>
</template>

<script>
import InvestmentBacktesting from './components/InvestmentBacktesting.vue';

export default {
    name: 'App',
    components: {
        InvestmentBacktesting,
    },
};
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
footer {
    background-color: #1a202c;
    color: #fff;
}
</style>
