<template>
    <div>
        <canvas ref="growthChartCanvas"></canvas>
    </div>
</template>

<script>
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

export default {
    name: 'GrowthChart',
    props: {
        chartData: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            chart: null,
        };
    },
    watch: {
        chartData: {
            handler(newData) {
                this.updateChart(newData);
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        createChart(data) {
            const ctx = this.$refs.growthChartCanvas.getContext('2d');
            if (ctx) {
                this.chart = new Chart(ctx, {
                    type: 'line',
                    data: data,
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                    },
                });
            }
        },
        async updateChart(data) {
            if (this.chart) {
                await this.chart.destroy();
                this.chart = null;
            }
            this.$nextTick(() => {
                this.createChart(data);
            });
        },
    },
    beforeUnmount() {
        if (this.chart) {
            this.chart.destroy();
            this.chart = null;
        }
    },
};
</script>

<style scoped>
div {
    height: 360px;
    max-width: 800px;
    margin: 0 auto;
}
</style>
